import api from "../apiConfig";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  info() {
    return api.get(`/api/v1/user-info`);
  },
  profilePicture(data) {
    return api.post(`/api/v1/user-profile`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};
