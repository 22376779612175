import OneSignal from "react-onesignal";
import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";

const profileApi = factory.get("profile");

const initialState = {
  user: {
    loading: false,
    info: {
      id: "",
      first_name: "",
      full_name: "",
      email: "",
      department_id: 0,
      department_name: "",
      job_title: "",
      active: true,
      gender: "",
      roles: null,
      permissions: [],
      profile_pic: "",
    },
  },
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    setProfile: (state, { payload }) => {
      Object.keys(state.user.info).map((key) => {
        if (key === "permissions") {
          payload.permissions.map((permission) => {
            state.user.info.permissions.push(permission.name);
          });
        } else {
          state.user.info[key] = payload[key];
        }
      });
      //   const profile_pic = payload.attachments.filter(
      //     (item) => item.tag === "profile_pic"
      //   );

      //   if (profile_pic.length > 0) {
      //     state.user.info.profile_pic = profile_pic[0].path;
      //   }
    },
    setProfilePicture: (state, { payload }) => {
      state.user.info.profile_pic = payload;
    },
  },
});

export const { setProfile, setProfilePicture, setLoading } =
  profileSlice.actions;

export default profileSlice.reducer;

//axios
export const Profile = {
  myInfo: (data) => async (dispatch) => {
    try {
      dispatch(setLoading("user"));
      const res = await profileApi.info();
      dispatch(setProfile(res.data.data[0]));

      OneSignal.setExternalUserId(res.data.data[0]?.id);

      dispatch(setLoading("user"));
    } catch (err) {
      dispatch(setLoading("user"));
      throw new Error(err);
    }
  },
  profilePicture: (profile_pic) => async (dispatch) => {
    try {
      dispatch(setLoading("user"));

      const formData = new FormData();

      formData.append("profile_pic", profile_pic);
      formData.append("mobile", true);

      const res = await profileApi.profilePicture(formData);

      dispatch(setProfilePicture(res.data.data[0].profile_pic.path));

      dispatch(
        showNotification({
          message: res.data.message,
          type: "success",
        })
      );
      dispatch(setLoading("user"));
      return {
        status: true,
      };
    } catch (err) {
      dispatch(setLoading("user"));
      throw new Error(err);
    }
  },
};
