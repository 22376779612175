import { createSlice } from "@reduxjs/toolkit";
import { factory } from "../../api/apiFactory";
import { showNotification } from "./notificationSlice";
import dayjs from "dayjs";
const cabinetsApi = factory.get("cabinets");
const cityApi = factory.get("city");

const initialState = {
  cabinets: {
    data: [],
    loading: false,
    total: 0,
    filters: {
      skip: 0,
      take: 15,
      page: 0,
      search: "",
    },
    dialog: false,
  },
  cabinet: {
    dialog: false,
    loading: false,
    form: {
      id: "",
      name: "",
      size: "",
      pole_no: "",
      pole_size: "",
      type: "",
      fees: "",
      note: "",
      city_id: null,
    },
    poles: [],
    errors: {},
  },
  city: {
    data: [],
    loading: false,
  },
};

const cabinetsSlice = createSlice({
  name: "cabinets",
  initialState,
  reducers: {
    setCabinets: (state, { payload }) => {
      state.cabinets.data = payload.data;
      state.cabinets.total = payload.total;
      state.cabinets.loading = false;
    },
    setLoading: (state, { payload }) => {
      state[payload].loading = !state[payload].loading;
    },
    setFilters: (state, { payload }) => {
      state.cabinets.filters = {
        ...state.cabinets.filters,
        ...payload,
      };
    },
    resetFilters: (state) => {
      state.cabinets.filters = initialState.cabinets.filters;
    },
    setDialog: (state, { payload }) => {
      if (payload?.id) {
        state.cabinet.form.id = payload.id;
        state.cabinet.form.name = payload.name;
        state.cabinet.form.size = payload.size;
        state.cabinet.form.poles = payload.poles;
        state.cabinet.form.type = payload.type;
        state.cabinet.form.fees = payload.fees;
        // state.cabinet.form.pole_size = payload.pole_size;
        state.cabinet.form.note = payload.note;
        state.cabinet.form.city_id = payload.city;
      }
      state.cabinet.dialog = !state.cabinet.dialog;
      //clear after close
      if (!state.cabinet.dialog) {
        state.cabinet.form = initialState.cabinet.form;
        state.cabinet.poles = initialState.cabinet.poles;
      }
    },
    setPole: (state, { payload }) => {
      state.cabinet.form.poles.push(payload);
    },
    updatePoleById: (state, { payload }) => {
      state.cabinet.form.poles = state.cabinet.form.poles.map((pole) => {
        if (pole.id === payload.id) {
          return payload;
        }
        return pole;
      });
    },
    setFiltersDialog: (state, { payload }) => {
      state.cabinet.dialog = !state.cabinet.dialog;
    },
    resetForm: (state) => {
      state.cabinet.form = initialState.cabinet.form;
    },
    setErrors: (state, { payload }) => {
      state.cabinet.errors = payload;
    },
    removePoleFromPoles: (state, { payload }) => {
      const poles = state.cabinet.poles.filter((pole) => pole.id !== payload);
      state.cabinet.poles = poles;
      state.cabinet.form.poles = state.cabinet.form.poles.filter(
        (pole) => pole.id !== payload
      );
    },
    setCity: (state, { payload }) => {
      state.city.data = payload;
    },
  },
});

export const {
  setCabinets,
  setLoading,
  setFilters,
  resetFilters,
  setDialog,
  resetForm,
  setErrors,
  setFiltersDialog,
  removePoleFromPoles,
  setCity,
  setPole,
  updatePoleById,
} = cabinetsSlice.actions;

export default cabinetsSlice.reducer;

//axios
export const index = () => async (dispatch, getState) => {
  try {
    const filters = getState().cabinets.cabinets.filters;
    dispatch(setLoading("cabinets"));
    const res = await cabinetsApi.index(filters);
    dispatch(setCabinets(res.data));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors,
        type: "error",
      })
    );
    dispatch(setLoading("cabinets"));
    throw new Error(err);
  }
};
export const store = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("cabinet"));
    const Data = {
      ...data,
      city_id: data.city_id?.id,
    };
    data.id ? await cabinetsApi.update(Data) : await cabinetsApi.create(Data);
    dispatch(setLoading("cabinet"));
    dispatch(
      showNotification({
        message: "تم الحفظ بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(resetForm());
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("cabinet"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    dispatch(setErrors(err.response.data.errors));
    throw new Error(err);
  }
};
export const deletePole = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("cabinet"));
    const res = await cabinetsApi.deletePole(id);
    dispatch(removePoleFromPoles(id));
    dispatch(setLoading("cabinet"));
    dispatch(
      showNotification({
        message: "تم الحذف بنجاح",
        type: "success",
      })
    );
    dispatch(index());
    return res;
    // dispatch(setDialog());
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    dispatch(setDialog());
    dispatch(setLoading("cabinet"));
    throw new Error(err);
  }
};
export const deleteCabinet = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("cabinets"));
    await cabinetsApi.delete(id);
    // dispatch(removePoleFromPoles(id));
    dispatch(setLoading("cabinets"));
    dispatch(
      showNotification({
        message: "تم الحذف بنجاح",
        type: "success",
      })
    );
    dispatch(index());
    return {
      success: true,
    };
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    dispatch(setLoading("cabinets"));
    throw new Error(err);
  }
};
export const addPole = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("cabinet"));
    const res = await cabinetsApi.createPole(data);
    dispatch(setLoading("cabinet"));
    dispatch(
      showNotification({
        message: "تم الحفظ بنجاح",
        type: "success",
      })
    );
    dispatch(setPole(res.data.data[0]));

    dispatch(index());
  } catch (err) {
    dispatch(setLoading("cabinet"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    dispatch(setErrors(err.response.data.errors));
    throw new Error(err);
  }
};
export const updatePole = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("cabinet"));
    const res = await cabinetsApi.updatePole(data);
    dispatch(setLoading("cabinet"));
    dispatch(
      showNotification({
        message: "تم الحفظ بنجاح",
        type: "success",
      })
    );
    dispatch(updatePoleById(res.data.data[0]));

    dispatch(index());
  } catch (err) {
    dispatch(setLoading("cabinet"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    dispatch(setErrors(err.response.data.errors));
    throw new Error(err);
  }
};
export const getCity = () => async (dispatch, getState) => {
  try {
    const city = getState().cabinets.city;
    if (city.data.length > 0) return;
    dispatch(setLoading("city"));
    const res = await cityApi.index({ take: 30 });
    dispatch(setCity(res.data.data));
    dispatch(setLoading("city"));
  } catch (err) {
    dispatch(setLoading("city"));
    throw new Error(err);
  }
};

export const toggleStatus = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("cabinets"));
    await cabinetsApi.toggleStatus(id);
    dispatch(setLoading("cabinets"));
    dispatch(
      showNotification({
        message: "تم تحويل الحالة بنجاح",
        type: "success",
      })
    );
    dispatch(index());
    return {
      success: true,
    };
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    dispatch(setLoading("cabinets"));
    throw new Error(err);
  }
};

export const Cabinets = {
  index,
  store,
  deletePole,
  deleteCabinet,
  addPole,
  getCity,
  updatePole,
  toggleStatus,
};
