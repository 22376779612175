import { createSlice } from "@reduxjs/toolkit";
import { factory } from "../../api/apiFactory";
import { showNotification } from "./notificationSlice";
const announcementApi = factory.get("announcement");

const initialState = {
  announcements: {
    data: [],
    loading: false,
    total: 0,
    filters: {
      skip: 0,
      take: 25,
      page: 0,
      search: "",
      is_hidden: null,
    },
    dialog: false,
  },
  announcement: {
    dialog: false,
    loading: false,
    form: {
      id: "",
      title: null,
      description: "",
      is_hidden: false,
    },
    errors: {},
  },
};

const announcementSlice = createSlice({
  name: "announcement",
  initialState,
  reducers: {
    setAnnouncements: (state, { payload }) => {
      state.announcements.data = payload.data;
      state.announcements.total = payload.total;
    },
    setLoading: (state, { payload }) => {
      state[payload].loading = !state[payload].loading;
    },
    setFilters: (state, { payload }) => {
      state.announcements.filters = {
        ...state.announcements.filters,
        ...payload,
      };
    },
    resetFilters: (state) => {
      state.announcements.filters = initialState.announcements.filters;
    },
    setDialog: (state, { payload }) => {
      state.announcement.form.id = payload?.id || "";
      state.announcement.form.title = payload?.title || "";
      state.announcement.form.description = payload?.description || "";
      state.announcement.form.is_hidden = payload?.is_hidden || false;

      state.announcement.dialog = !state.announcement.dialog;
    },
    setFiltersDialog: (state, { payload }) => {
      state.announcements.dialog = !state.announcements.dialog;
    },
  },
});

export const {
  setAnnouncements,
  setLoading,
  setFilters,
  resetFilters,
  setDialog,
  setFiltersDialog,
} = announcementSlice.actions;

export default announcementSlice.reducer;

const index = () => async (dispatch, getState) => {
  try {
    dispatch(setLoading("announcements"));
    const filters = getState().announcement.announcements.filters;
    const filters_params = {
      ...filters,
      //   account_type_id: filters.account_type_id?.id || null,
      //   customer_id: filters.customer_id?.id || null,
      //   city_id: filters.city_id?.id || null,
      //   client_id: filters.client_id?.id || null,
      //   created_by_id: filters.created_by_id?.id || null,
    };
    const res = await announcementApi.index(filters_params);
    dispatch(setAnnouncements(res.data));
    dispatch(setLoading("announcements"));
  } catch (err) {
    dispatch(setLoading("announcements"));
    throw new Error(err);
  }
};
const create = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("announcement"));
    const form = {
      title: data.title,
      description: data.description,
      is_hidden: data.is_hidden,
    };
    await announcementApi.create(form);
    dispatch(setLoading("announcement"));
    dispatch(
      showNotification({
        message: "تم إضافة الأعلان بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(index());
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    dispatch(setLoading("announcement"));
    throw new Error(err);
  }
};
const update = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("announcement"));
    const form = {
      id: data.id,
      title: data.title,
      description: data.description,
      is_hidden: data.is_hidden,
    };
    await announcementApi.update(form);
    dispatch(setLoading("announcement"));
    dispatch(
      showNotification({
        message: "تم تعديل الأعلان بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(index());
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    dispatch(setLoading("announcement"));
    throw new Error(err);
  }
};
const deleteAnnouncement = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("announcements"));
    await announcementApi.delete(id);
    dispatch(
      showNotification({
        type: "success",
        message: "تم الحذف  بنجاح",
      })
    );
    dispatch(setLoading("announcements"));
    dispatch(index());
    return {
      success: true,
    };
  } catch (err) {
    dispatch(setLoading("announcements"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};

export const Announcements = {
  index,
  create,
  update,
  deleteAnnouncement,
};
