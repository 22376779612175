import { useSelector } from "react-redux";

const CanIsee = ({ permission, children }) => {
  const {
    user: {
      info: { permissions },
    },
  } = useSelector((state) => state.profile);
  return permissions.includes(permission) ? children : null;
};

export default CanIsee;
