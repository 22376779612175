import { useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import { Box, CssBaseline } from "@mui/material";

import SideBar from "./Sidebar";
import AppBar from "./AppBar";
import { useDispatch, useSelector } from "react-redux";
import { Profile } from "../../app/slices/profileSlice";
import Cookies from "js-cookie";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    backgroundColor: "#FAFAFA",
    flexGrow: 1,
    width: "100%",
    minHeight: "calc(100vh - 80px)",
    padding: "10px",
    marginTop: "75px",
    borderRadius: `8px`,
    ...(!open && {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    }),
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      // width: `100%`,
      // [theme.breakpoints.down("md")]: {
      //   marginLeft: "20px",
      // },
      // [theme.breakpoints.down("sm")]: {
      //   marginLeft: "0px",
      // },
    }),
    // transition: theme.transitions.create("margin", {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
    // marginLeft: -(260 - 40),
    // ...(open && {
    //   transition: theme.transitions.create("margin", {
    //     easing: theme.transitions.easing.easeOut,
    //     duration: theme.transitions.duration.enteringScreen,
    //   }),
    //   marginLeft: 0,
    // }),
  })
);

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const dispatch = useDispatch();
  const leftDrawerOpened = useSelector((state) => state.app.is_drawer_opened);
  const { user } = useSelector((state) => state.profile);
  const token = Cookies.get("halasat-ftth-token-1");

  useEffect(() => {
    if (user.info.id == "") {
      dispatch(Profile.myInfo());
    }
  }, []);

  return token ? (
    <Box sx={{ display: "flex" }} dir="rtl">
      <CssBaseline />
      <AppBar />
      <SideBar />
      <Main open={leftDrawerOpened}>
        <Outlet />
      </Main>
    </Box>
  ) : (
    <Navigate to="/login" replace={true} />
  );
};

export default MainLayout;
