import { createSlice } from "@reduxjs/toolkit";
import { factory } from "../../api/apiFactory";
import { showNotification } from "./notificationSlice";
import dayjs from "dayjs";

const subscriptionsServicesApi = factory.get("subscriptions_services");

const initialState = {
  subscriptions_servicess: {
    data: [],
    loading: false,
    total: 0,
    dialog: false,
    filters: {
      skip: 0,
      take: 25,
      page: 0,
      client_id: null,
    },
  },
};

const subscriptionsServicesSlice = createSlice({
  name: "subscriptionsServices",
  initialState,
  reducers: {
    setSubscriptionsServices: (state, { payload }) => {
      state.subscriptions_servicess.data = payload.data;
      state.subscriptions_servicess.total = payload.total;
      state.subscriptions_servicess.loading = false;
    },
    setLoading: (state, { payload }) => {
      state[payload].loading = !state[payload].loading;
    },
    setFilters: (state, { payload }) => {
      state.subscriptions_servicess.filters = {
        ...state.subscriptions_servicess.filters,
        ...payload,
      };
    },
    resetFilters: (state) => {
      state.subscriptions_servicess.filters =
        initialState.subscriptions_servicess.filters;
    },
  },
});

export const {
  setSubscriptionsServices,
  setLoading,
  setFilters,
  resetFilters,
  setDialog,
  resetForm,
  setErrors,
  setServices,
} = subscriptionsServicesSlice.actions;

export default subscriptionsServicesSlice.reducer;

//axios
export const index = () => async (dispatch, getState) => {
  try {
    const filters =
      getState().subscriptionsServices.subscriptions_servicess.filters;
    const filters_params = {
      ...filters,
      client_id: filters.client_id?.id || null,
    };
    dispatch(setLoading("subscriptions_servicess"));
    const res = await subscriptionsServicesApi.index(filters_params);
    dispatch(setSubscriptionsServices(res.data));
  } catch (err) {
    dispatch(setLoading("subscriptions_servicess"));
    throw new Error(err);
  }
};

export const SubscriptionsServices = {
  index,
};
