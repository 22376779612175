import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import { useEffect, forwardRef, useState } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import * as React from "react";

import { factory } from "../../api/apiFactory";
const clientsApi = factory.get("clients");
export const SelectClient = forwardRef((props, ref) => {
  const [options, setOptions] = useState([]);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchClients = () => {
    setLoading(true);
    clientsApi.getList().then((res) => {
      setOptions(res.data.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (!props.preFetchedClinets) {
      fetchClients();
    } else {
      setOptions(props.preFetchedClinets);
    }
  }, [props.preFetchedClinets]);

  return (
    <Autocomplete
      {...props}
      ref={ref}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        );
      }}
      isOptionEqualToValue={(option, value) => option?.id === value?.id || ""}
      getOptionLabel={(option) => `${option.name}`}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          error={props.error ? props.error : null}
          helperText={props.helperText ? props.helperText : null}
          label={props.label || "الوكلاء"}
          size="small"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
});
