import { createSlice } from "@reduxjs/toolkit";
import { factory } from "../../api/apiFactory";
import { showNotification } from "./notificationSlice";
const variablesApi = factory.get("variables");

const initialState = {
  variables: {
    data: [],
    loading: false,
    total: 0,
    filters: {
      skip: 0,
      take: 15,
      page: 0,
    },
  },
  variable: {
    dialog: false,
    loading: false,
    form: {
      id: "",
      key: "",
      value: null,
      note: "",
    },
  },
};

const variablesSlice = createSlice({
  name: "variables",
  initialState,
  reducers: {
    setVariables: (state, { payload }) => {
      state.variables.data = payload.data;
      state.variables.total = payload.total;
    },
    setLoading: (state, { payload }) => {
      state[payload].loading = !state[payload].loading;
    },
    setDialog: (state, { payload }) => {
      if (payload?.id) {
        state.variable.form.id = payload.id;
        state.variable.form.key = payload.key;
        state.variable.form.value = payload.value;
        state.variable.form.note = payload.note;
      }
      state.variable.dialog = !state.variable.dialog;
      //clear after close
      if (!state.variable.dialog) {
        state.variable.form = initialState.variable.form;
      }
    },
    setFilters: (state, { payload }) => {
      state.variables.filters = {
        ...state.variables.filters,
        ...payload,
      };
    },
    resetFilters: (state) => {
      state.variables.filters = initialState.variables.filters;
    },
  },
});

export const { setVariables, setLoading, setDialog, setFilters, resetFilters } =
  variablesSlice.actions;

export default variablesSlice.reducer;

const toNumber = (value) => {
  return typeof value === "number" ? value : parseInt(value.replace(/,/g, ""));
};

export const index = () => async (dispatch, getState) => {
  try {
    const filters = getState().variables.variables.filters;
    dispatch(setLoading("variables"));
    const res = await variablesApi.index(filters);
    dispatch(setVariables(res.data));
    dispatch(setLoading("variables"));
  } catch (err) {
    dispatch(setLoading("variables"));
    throw new Error(err);
  }
};
export const update = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("variable"));
    await variablesApi.update({
      id: data.id,
      value: toNumber(data.value),
      note: data.note,
    });
    dispatch(setLoading("variable"));
    dispatch(
      showNotification({
        message: "تم الحفظ بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("variable"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const Variables = {
  index,
  update,
};
