import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import ListItemIcon from "@mui/material/ListItemIcon";
import { BiLogOutCircle, BiUserCircle } from "react-icons/bi";
import { GoDeviceMobile } from "react-icons/go";
import {
  List,
  ListItemAvatar,
  ListItemText,
  ListItemButton,
  Avatar,
  Divider,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Auth } from "../../../app/slices/authSlice";
import { TbSignature } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { TbFileInvoice } from "react-icons/tb";
import { setBalanceDialog } from "../../../app/slices/clientsSlice";
import BalanceDialog from "../../../components/Shared/clients/BalanceDialog";
import CanIsee from "../../../helpers/CanIsee";

const ProfileMenu = () => {
  const { user } = useSelector((state) => state.profile);
  const { balance } = useSelector((state) => state.clients);

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();

  return (
    <div>
      <List role={"button"} onClick={handleMenu}>
        <ListItemButton>
          <ListItemAvatar>
            {user.info.profile_pic ? (
              <Avatar
                src={user.info.profile_pic}
                sx={{
                  border: "1px solid wheat",
                }}
              ></Avatar>
            ) : (
              <Avatar>
                <AccountCircle />
              </Avatar>
            )}
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant="h6" fontWeight={"bold"}>
                {user.info?.full_name}
              </Typography>
            }
            secondary={
              <Typography variant="caption" color="wheat">
                {user.info.job_title}
              </Typography>
            }
          />
        </ListItemButton>
      </List>

      <Menu
        dir="rtl"
        sx={{ mt: "45px" }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <CanIsee permission="is_collector">
          <MenuItem
            onClick={() => {
              dispatch(
                setBalanceDialog({
                  is_collector: true,
                })
              );
              handleClose();
            }}
          >
            <ListItemIcon>
              <TbFileInvoice />
            </ListItemIcon>
            <ListItemText primary="إضافة رصيد للوكيل" />
          </MenuItem>
          {balance.dialog && <BalanceDialog />}
        </CanIsee>

        <Divider />
        <MenuItem
          onClick={() => {
            handleClose();
            dispatch(Auth.logout());
          }}
        >
          <ListItemIcon>
            <BiLogOutCircle />
          </ListItemIcon>
          <ListItemText primary="تسجيل خروج" />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ProfileMenu;
