import OneSignal from "react-onesignal";
import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { factory } from "../../api/apiFactory";
import { showNotification } from "./notificationSlice";
import { setProfile } from "./profileSlice";
import Cookies from "js-cookie";
import axios from "axios";

const authApi = factory.get("auth");

const initialState = {
  auth: {
    loading: false,
    token: "",
  },
  login: {
    loading: false,
    form: {
      email: "",
      password: "",
      otp: "",
    },
  },
  otp: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    setToken: (state, action) => {
      Cookies.set("halasat-ftth-token-1", action.payload, {
        expires: dayjs().add(3, "day").toDate(),
      });
      window.location.href = "/";
    },
    setOtp: (state, action) => {
      state.otp = true;
      state.login.form.password = "";
    },
  },
});

export const { setLoading, setToken, setOtp } = authSlice.actions;

export default authSlice.reducer;

//axios
const login = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("auth"));
    //step1 get user agent and platform
    const userAgentNavigator = window.navigator.userAgent;
    const platform = window.navigator.platform;

    //step2 ip info
    let ip_info = {
      ip: "",
      city: "",
      region: "",
      country: "",
      loc: "",
      org: "",
      timezone: "",
    };
    await axios
      .get("https://ipinfo.io/json")
      .then((res) => {
        ip_info = {
          ...res.data,
        };
        delete ip_info.readme;
      })
      .catch((err) => {
        return ip_info;
      });
    let user_agent = JSON.stringify({
      ...ip_info,
      user_agent: userAgentNavigator,
      platform: platform,
    });

    //step3 login
    let res;
    if (!data.otp) {
      delete data.otp;
      res = await authApi.login({
        email: data.email,
        password: data.password,
        user_agent: user_agent,
      });
    } else {
      //step 3.1 verify otp
      res = await authApi.loginVerify({
        email: data.email,
        code: data.otp,
        user_agent: user_agent,
      });
    }

    //step4 if no token set otp to true
    if (!res.data.token) {
      dispatch(setOtp());
    }
    //step5 else have token => set token dierctly and redirect to home page
    else {
      dispatch(setToken(res.data.token));
      dispatch(setProfile(res.data.data[0]));
      dispatch(setToken(res.data.token));
    }
    dispatch(setLoading("auth"));
  } catch (err) {
    dispatch(setLoading("auth"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const logout = () => async (dispatch) => {
  try {
    dispatch(setLoading("auth"));
    await authApi.logout().then((res) => {
      Cookies.remove("halasat-ftth-token-1");
      window.location.href = "/login";
    });
    dispatch(setLoading("auth"));
  } catch (err) {
    dispatch(setLoading("auth"));
    throw new Error(err);
  }
};

export const Auth = {
  login,
  logout,
};
