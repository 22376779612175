import api from "../apiConfig";
const paramsFactory = (p) => {
  const params = new URLSearchParams();
  if (p) {
    Object.keys(p).forEach((key) => {
      if (p[key] !== null && p[key] !== undefined && p[key] !== "") {
        params.append(key, p[key]);
      }
    });
  }
  return params;
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  index(params) {
    const p = paramsFactory(params);
    return api.get(`/api/v1/client-subscription-service?${p}`);
  },
  // getServices() {
  //   return api.get(`client/subscription-service`);
  // },
  // subscribeToService(data) {
  //   return api.post(`client/subscription-service/activation/${data.id}`, data);
  // },
};
