import api from "../apiConfig";
const paramsFactory = (p) => {
  const params = new URLSearchParams();
  if (p) {
    Object.keys(p).forEach((key) => {
      if (p[key] !== null && p[key] !== undefined && p[key] !== "") {
        params.append(key, p[key]);
      }
    });
  }
  return params;
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  index(params) {
    const p = paramsFactory(params);
    return api.get(`/api/v1/offers?${p}`);
  },
  update(id, cabinet_ids) {
    return api.put(`/api/v1/offers/assign_cabinet/${id}`, { cabinet_ids });
  },
  toggleActive(id) {
    return api.put(`/api/v1/offers/${id}`);
  },
  activeOffersList() {
    return api.get(`/api/v1/offers/list`);
  },
};
