import { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Grid,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Clients, setBalanceDialog } from "../../../app/slices/clientsSlice";
import { Controller, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { NumericFormat } from "react-number-format";
import { SelectClient } from "../../Selectors/SelectClient";

const ClientDialog = () => {
  const { balance } = useSelector((state) => state.clients);

  const dispatch = useDispatch();

  const {
    control,
    reset,
    watch,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: balance.form,
    shouldFocusError: false,
  });

  useEffect(() => {
    reset({ ...balance.form });
  }, [balance.form]);

  const onSubmit = (data, event) => {
    dispatch(Clients.addRemoveBalance(data));
  };

  return (
    <Dialog
      open={balance.dialog}
      keepMounted
      dir="rtl"
      maxWidth="md"
      onClose={() => {
        dispatch(setBalanceDialog());
      }}
    >
      <DialogTitle
        dir="rtl"
        children={
          <span>
            <Typography variant="h4" color="primary">
              {watch("is_collector") ? "اضافة رصيد للوكيل" : "اضافة و سحب رصيد"}
            </Typography>

            {!watch("is_collector") && (
              <Typography variant="h4" color="gray" my={1}>
                {`الوكيل : ${watch("name")}`}
              </Typography>
            )}
          </span>
        }
      />

      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            spacing={2}
            sx={{
              pt: 1,
            }}
          >
            {watch("is_collector") && (
              <Grid item xs={12}>
                <Controller
                  name="client_id"
                  control={control}
                  rules={{
                    required: { value: true, message: "الحقل مطلوب" },
                  }}
                  render={({ field }) => (
                    <SelectClient
                      size="small"
                      value={field.value}
                      label="اختر الوكيل"
                      fullWidth
                      error={!!errors.client_id}
                      helperText={
                        errors.client_id ? errors.client_id.message : ""
                      }
                      onChange={(e, newValue) => {
                        field.onChange(newValue || null);
                      }}
                    />
                  )}
                />
              </Grid>
            )}
            {!watch("is_collector") && (
              <Grid item xs={12}>
                <Controller
                  name="amount_type"
                  control={control}
                  render={({ field }) => (
                    <ToggleButtonGroup
                      exclusive
                      color="primary"
                      size="small"
                      fullWidth
                      name="amount_type"
                      {...field}
                      value={field.value || "+"}
                      onChange={(e, newValue) => {
                        if (!newValue) return;
                        setValue("amount_type", newValue);
                      }}
                    >
                      <ToggleButton value={"+"}>إضافة</ToggleButton>
                      <ToggleButton value={"-"}>سحب</ToggleButton>
                    </ToggleButtonGroup>
                  )}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <Controller
                name="amount"
                control={control}
                rules={{
                  required: { value: true, message: "الحقل مطلوب" },
                }}
                render={({ field }) => (
                  <NumericFormat
                    value={field.value}
                    thousandSeparator
                    customInput={TextField}
                    valueIsNumericString={true}
                    allowNegative={false}
                    label="المبلغ"
                    size="small"
                    fullWidth
                    {...field}
                    error={!!errors["amount"]}
                    helperText={
                      errors["amount"] ? errors["amount"].message : ""
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <TextField
                    multiline
                    rows={2}
                    size="small"
                    label="ملاحظات"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <LoadingButton
                name="mainSubmit"
                type="submit"
                variant="contained"
                color={"success"}
                loading={balance.loading}
              >
                تنفيذ العملية
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ClientDialog;
