import { createSlice } from "@reduxjs/toolkit";
import { factory } from "../../api/apiFactory";
import { showNotification } from "./notificationSlice";
const ticketsApi = factory.get("tickets");

const initialState = {
  tickets: {
    data: [],
    loading: false,
    total: 0,
    filters: {
      skip: 0,
      take: 25,
      page: 0,
      search: "",
      status: null,
      is_open: null,
    },
    dialog: false,
  },
  status: {
    loading: false,
    data: [],
  },
};

const ticketsSlice = createSlice({
  name: "tickets",
  initialState,
  reducers: {
    setTickets: (state, { payload }) => {
      state.tickets.data = payload.data;
      state.tickets.total = payload.total;
    },
    setStatus: (state, { payload }) => {
      state.status.data = payload.data;
    },
    setLoading: (state, { payload }) => {
      state[payload].loading = !state[payload].loading;
    },
    setFilters: (state, { payload }) => {
      state.tickets.filters = {
        ...state.tickets.filters,
        ...payload,
      };
    },
    resetFilters: (state) => {
      state.tickets.filters = initialState.tickets.filters;
    },
  },
});

export const { setTickets, setLoading, setFilters, resetFilters, setStatus } =
  ticketsSlice.actions;

export default ticketsSlice.reducer;

//axios
export const index = () => async (dispatch, getState) => {
  try {
    const filters = getState().tickets.tickets.filters;
    const filters_params = {
      ...filters,
      status: filters.status?.status || null,
    };
    dispatch(setLoading("tickets"));
    const res = await ticketsApi.index(filters_params);
    dispatch(setTickets(res.data));
    dispatch(setLoading("tickets"));
  } catch (err) {
    dispatch(setLoading("tickets"));
    throw new Error(err);
  }
};
export const getStatus = () => async (dispatch, getState) => {
  try {
    dispatch(setLoading("status"));
    const res = await ticketsApi.status();
    dispatch(setStatus(res.data));
    dispatch(setLoading("status"));
  } catch (err) {
    dispatch(setLoading("status"));
    throw new Error(err);
  }
};

export const Tickets = {
  index,
  getStatus,
};
