// import { useSelector } from 'react-redux';

import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { arEG } from "@mui/material/locale";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import "./App.css";
import NotificationSnackbar from "../src/components/Notification";
import { LicenseInfo } from "@mui/x-license-pro";
// routing
import Routes from "./routes";

// project imports
import NavigationScroll from "./layout/NavigationScroll";
import themeTypography from "./themes/typography";

import ConfirmationDialog from "./components/Shared/ConfirmationDialog";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tasks } from "./app/slices/taskSlice";
import { Cabinets } from "./app/slices/cabinetsSlice";
import runOneSignal from "./onesignal/config";
import OneSignal from "react-onesignal";

// ==============================|| APP ||============================== //

const App = () => {
  const {
    user: {
      info: { id },
    },
  } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  LicenseInfo.setLicenseKey(
    "186a4fab9c1d8ad1a6680236cc5071c9Tz01ODIzMCxFPTE3MDYwMDYzODgwMzQsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
  );

  useEffect(() => {
    runOneSignal();
  }, []);

  useEffect(() => {
    const handleNotificationReceived = (event) => {
      dispatch(Tasks.getTasksNotifications());
    };

    if (id) {
      OneSignal.on("notificationDisplay", handleNotificationReceived);

      // if user already is logged in , set External User Id  first time permission changes
      OneSignal.on("notificationPermissionChange", (permissionChange) => {
        let currentPermission = permissionChange.to;
        if (currentPermission === "granted") {
          OneSignal.setExternalUserId(id);
        }
      });

      return () => {
        OneSignal.off("notificationDisplay", handleNotificationReceived);
      };
    }
  }, [id]);

  useEffect(() => {
    //if user is logged in and has id
    if (id) {
      dispatch(Tasks.status());
      dispatch(Tasks.solutions());
      dispatch(Cabinets.getCity());
      //first time to get notifications
      dispatch(Tasks.getTasksNotifications());
    }
  }, [id]);

  const border_shadow = {
    borderRadius: 10,
    boxShadow:
      "rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px",
  };
  const theme = createTheme(
    {
      components: {
        MuiTypography: {
          defaultProps: {
            fontFamily: "Cairo",
          },
        },

        // Name of the component
        MuiCard: {
          styleOverrides: {
            root: {
              borderRadius: border_shadow.borderRadius,
              boxShadow: border_shadow.boxShadow,
            },
          },
        },
        MuiDataGrid: {
          styleOverrides: {
            root: {
              borderRadius: border_shadow.borderRadius,
              boxShadow: border_shadow.boxShadow,
            },
          },
        },
        // MuiListItemText: {
        //   styleOverrides: {
        //     primary: {
        //       // color: 'b'
        //     },
        //     "&:hover": {
        //       backgroundColor: "blue",
        //       color: "green"
        //     }
        //   }
        // },
      },
      palette: {
        type: "light",
        primary: {
          main: "#6941c5",
          contrastText: "#ffffff",
          light: "#6941c543",
        },
        secondary: {
          main: "#340E58",
        },
      },
      typography: themeTypography,
      direction: "rtl",
    },
    arEG
  );
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin],
  });

  return (
    <StyledEngineProvider injectFirst>
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={theme}>
          <ConfirmationDialog />
          <CssBaseline />
          <NotificationSnackbar />
          <NavigationScroll>
            <Routes />
          </NavigationScroll>{" "}
        </ThemeProvider>{" "}
      </CacheProvider>{" "}
    </StyledEngineProvider>
  );
};

export default App;
