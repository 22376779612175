import { createSlice } from "@reduxjs/toolkit";
import { factory } from "../../api/apiFactory";
import { showNotification } from "./notificationSlice";
const offersApi = factory.get("offers");

const initialState = {
  offers: {
    data: [],
    loading: false,
    total: 0,
    filters: {
      skip: 0,
      take: 15,
      page: 0,
    },
  },
  offer: {
    dialog: false,
    loading: false,
    form: {
      id: "",
      cabinet_ids: [],
    },
  },
  active_offers_list: {
    loading: false,
    list: [],
  },
};

const variablesSlice = createSlice({
  name: "offers",
  initialState,
  reducers: {
    setOffers: (state, { payload }) => {
      state.offers.data = payload.data;
      state.offers.total = payload.total;
    },
    setLoading: (state, { payload }) => {
      state[payload].loading = !state[payload].loading;
    },
    setDialog: (state, { payload }) => {
      if (payload?.id) {
        state.offer.form.id = payload.id;
        state.offer.form.cabinet_ids = payload.cabinet_ids;
      }
      state.offer.dialog = !state.offer.dialog;
      //clear after close
      if (!state.offer.dialog) {
        state.offer.form = initialState.offer.form;
      }
    },
    setFilters: (state, { payload }) => {
      state.offers.filters = {
        ...state.offers.filters,
        ...payload,
      };
    },
    resetFilters: (state) => {
      state.offers.filters = initialState.offers.filters;
    },
    setActiveOffersList: (state, { payload }) => {
      state.active_offers_list.list = payload || [];
    },
  },
});

export const {
  setOffers,
  setLoading,
  setDialog,
  setFilters,
  resetFilters,
  setActiveOffersList,
} = variablesSlice.actions;

export default variablesSlice.reducer;

export const index = () => async (dispatch, getState) => {
  try {
    const filters = getState().offers.offers.filters;
    dispatch(setLoading("offers"));
    const res = await offersApi.index(filters);
    dispatch(setOffers(res.data));
    dispatch(setLoading("offers"));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors,
        type: "error",
      })
    );
    dispatch(setLoading("offers"));
    throw new Error(err);
  }
};
export const updateCabinets = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("offer"));
    const cabinet_ids = data.cabinet_ids.map((item) => item.id);
    await offersApi.update(data.id, cabinet_ids);
    dispatch(setLoading("offer"));
    dispatch(
      showNotification({
        message: "تم الحفظ بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("offer"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
export const toggleActive = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("offers"));
    await offersApi.toggleActive(id);
    dispatch(setLoading("offers"));
    dispatch(
      showNotification({
        message: "تم تغيير الحالة بنجاح",
        type: "success",
      })
    );
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("offers"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
export const activeOffersList = () => async (dispatch, getState) => {
  try {
    dispatch(setLoading("active_offers_list"));
    const res = await offersApi.activeOffersList();
    dispatch(setActiveOffersList(res.data.data));
    dispatch(setLoading("active_offers_list"));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors,
        type: "error",
      })
    );
    dispatch(setLoading("active_offers_list"));
    throw new Error(err);
  }
};

export const OffersActions = {
  index,
  updateCabinets,
  toggleActive,
  activeOffersList,
};
