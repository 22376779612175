import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { factory } from "../../api/apiFactory";
import { showNotification } from "./notificationSlice";
import api from "../../api/apiConfig";
const taskApi = factory.get("task");

const initialState = {
  tasks: {
    loading: false,
    data: [],
    total: 0,
    filters: {
      skip: 0,
      take: 25,
      page: 0,
      search: "",
      start_date: null,
      end_date: null,
      start_time: null,
      end_time: null,
      status: [],
      task_id: "",
      responsible_id: null,
      city_id: null,
      customers_source: null,
      has_responsible: null,
      has_team_leader: null,
      team_leader_id: null,
      created_by_id: null,
    },
    filters_dialog: false,
  },
  task: {
    form: {
      id: null,
      type: 1,
      status: "new",
      note: "",
      name: "",
      mobile: "",
      is_external: false,
      customer_id: null,
      cabinet_id: null,
      pole_id: null,
      city_id: null,
      attachment: [],
      is_change_subscription: false,
    },
    dialog: false,
    loading: false,
    errors: {},
  },
  task_maintenance: {
    loading: false,
    data: [],
    total: 0,
    filters: {
      skip: 0,
      take: 25,
      page: 0,
      search: "",
      start_date: null,
      end_date: null,
      start_time: null,
      end_time: null,
      type_id: null,
      status: [],
      user_id: null,
      city_id: null,
      category: "",
      responsible_id: null,
      cabinet_id: null,
      task_id: "",
      client_id: null,
      customers_source: null,
      is_external: null,
      has_responsible: null,
      has_team_leader: null,
      team_leader_id: null,
      created_by_id: null,
    },
    filters_dialog: false,
  },
  task_subscription_request: {
    loading: false,
    data: [],
    total: 0,
    filters: {
      skip: 0,
      take: 25,
      page: 0,
      search: "",
      user_id: null,
      city_id: null,
      cabinet_ids: [],
      responsible_id: null,
      status: [{ value: "جديد", key: "new" }],
      customers_source: "1",
      created_by_id: null,
      start_date: null,
      end_date: null,
      start_time: null,
      end_time: null,
    },
    filters_dialog: false,
  },
  status: {
    data: [],
    loading: false,
  },
  solutions: {
    data: [],
    loading: false,
  },
  types: {
    data: [],
    loading: false,
  },
  task_details: {
    data: {
      id: null,
      type: "",
      name: "",
      mobile: "",
      note: "",
      status: "",
      customer: null,
      responsible_id: null,
      responsible_name: null,
      created_by_id: "",
      solution: [],
      created_by: "",
      attachment: "",
      replies: [],
    },
    loading: false,
  },
  reply: {
    progress: 0,
    form: {
      task_id: "",
      note: "",
      attachment: [],
      video: null,
    },
    loading: false,
    dialog: false,
    errors: {},
  },
  responsible: {
    form: {
      tasks: [],
      responsible_id: null,
      from: "task",
    },
    loading: false,
    dialog: false,
  },
  assign_team_leader: {
    form: {
      id: "",
      team_leader_id: null,
      from: "task",
    },
    loading: false,
    dialog: false,
  },
  task_notifications: {
    new_customer: 0,
    maintenance: 0,
    subscription_request: 0,
  },
};

export const taskSlice = createSlice({
  name: "task",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    // Tasks
    setTasks: (state, action) => {
      state.tasks.data = action.payload.data.data;
      state.tasks.total = action.payload.data.total;
    },
    setTasksFiltersDialog: (state, action) => {
      state.tasks.filters_dialog = !state.tasks.filters_dialog;
    },
    setTaskReplyDialog: (state, { payload }) => {
      state.reply.dialog = !state.reply.dialog;
      state.reply.form.task_id = payload ? payload : "";
      state.reply.progress = 0;
    },
    resetForm: (state, action) => {
      state.task.form = initialState.task.form;
    },
    setFilters: (state, { payload }) => {
      if (payload.status) {
        state.tasks.filters.status = payload.status;
      } else {
        state.tasks.filters = {
          ...state.tasks.filters,
          ...payload,
        };
      }
    },
    setTaskDetails: (state, { payload }) => {
      state.task_details.data = { ...payload };
    },
    resetFilters: (state) => {
      state.tasks.filters = initialState.tasks.filters;
    },
    setDialog: (state, { payload }) => {
      if (payload) {
        state.task.form.type = payload.type;

        //if the form type is subscription request and change subscription is true
        if (payload?.is_change_subscription) {
          state.task.form.id = payload.data.id;
          state.task.form.customer_id = {
            id: payload.data.customer_id,
            full_name: payload.data.name,
            mobile: payload.data.mobile,
          };
          state.task.form.account_type_id = {
            id: payload.data.account_type_id,
            name: payload.data.account_type_name,
          };
          state.task.form.last_amount = payload.data.amount;
          state.task.form.is_change_subscription =
            payload?.is_change_subscription || false;
        }
      } else {
        state.task.form = initialState.task.form;
      }
      state.task.dialog = !state.task.dialog;
    },
    // END Tasks

    //Maintenance Tasks
    setMaintenanceTasks: (state, action) => {
      state.task_maintenance.data = action.payload.data.data;
      state.task_maintenance.total = action.payload.data.total;
    },
    setMaintenanceTasksFiltersDialog: (state, action) => {
      state.task_maintenance.filters_dialog =
        !state.task_maintenance.filters_dialog;
    },
    setMaintenanceFilters: (state, { payload }) => {
      if (payload.status) {
        state.task_maintenance.filters.status = payload.status;
      } else {
        state.task_maintenance.filters = {
          ...state.task_maintenance.filters,
          ...payload,
        };
      }
    },
    resetMaintenanceFilters: (state) => {
      state.task_maintenance.filters = initialState.task_maintenance.filters;
    },
    // END Maintenance Tasks

    // Subscription Request Tasks
    setSubscriptionRequestTasks: (state, action) => {
      state.task_subscription_request.data = action.payload.data.data;
      state.task_subscription_request.total = action.payload.data.total;
    },
    setSubscriptionRequestTaskFilterDialog: (state) => {
      state.task_subscription_request.filters_dialog =
        !state.task_subscription_request.filters_dialog;
    },
    setSubscriptionRequestTaskFilters: (state, { payload }) => {
      if (payload.status) {
        state.task_subscription_request.filters.status = payload.status;
      } else {
        state.task_subscription_request.filters = {
          ...state.task_subscription_request.filters,
          ...payload,
        };
      }
    },
    resetSubscriptionRequestTaskFilters: (state) => {
      state.task_subscription_request.filters =
        initialState.task_subscription_request.filters;
    },

    // END Subscription Request Tasks

    setStatus: (state, { payload }) => {
      state.status.data = payload.data.data;
      state.status.loading = false;
    },
    setRsponsibleDialog: (state, { payload }) => {
      if (payload) {
        state.responsible.form.tasks = payload.tasks;
        state.responsible.form.from = payload.from;
      }
      state.responsible.dialog = !state.responsible.dialog;
    },
    setSolutions: (state, { payload }) => {
      state.solutions.data = payload.data.data;
      state.solutions.loading = false;
    },
    setTypes: (state, { payload }) => {
      state.types.data = payload.data.data;
      state.types.loading = false;
    },
    setReplyProgress: (state, { payload }) => {
      state.reply.progress = payload;
    },
    setTaskNotifications: (state, { payload }) => {
      state.task_notifications.new_customer =
        payload.data?.data[0].new_customer;
      state.task_notifications.subscription_request =
        payload.data?.data[0].subscription_request;
      state.task_notifications.maintenance = payload.data?.data[0].maintenance;
    },
    setTeamLeaderDialog: (state, { payload }) => {
      if (payload) {
        state.assign_team_leader.form.id = payload.id;
        state.assign_team_leader.form.from = payload.from;
        state.assign_team_leader.form.team_leader_id = payload.team_leader_id
          ?.id
          ? payload.team_leader_id
          : null;
      } else {
        state.assign_team_leader.form = initialState.assign_team_leader.form;
      }
      state.assign_team_leader.dialog = !state.assign_team_leader.dialog;
    },
  },
});

export const {
  setLoading,
  setTasks,
  setFilters,
  setStatus,
  resetFilters,
  setDialog,
  resetForm,
  setTasksFiltersDialog,
  setMaintenanceTasksFiltersDialog,
  setTaskReplyDialog,
  setTaskDetails,
  setRsponsibleDialog,
  setSolutions,
  setTypes,
  setMaintenanceTasks,
  setMaintenanceFilters,
  resetMaintenanceFilters,
  setReplyProgress,
  setTaskNotifications,

  setSubscriptionRequestTasks,
  setSubscriptionRequestTaskFilterDialog,
  setSubscriptionRequestTaskFilters,
  resetSubscriptionRequestTaskFilters,
  setTeamLeaderDialog,
} = taskSlice.actions;

export default taskSlice.reducer;

const toNumber = (value) => {
  return typeof value === "number" ? value : parseInt(value.replace(/,/g, ""));
};

//axios
const index = () => async (dispatch, getState) => {
  try {
    const filters = getState().task.tasks.filters;
    const filters_ = {
      ...filters,
      city_id: filters.city_id?.id || null,
      team_leader_id: filters.team_leader_id?.id || null,
      responsible_id: filters.responsible_id?.id || null,
      status: filters.status ? filters.status.map((item) => item.key) : null,
      created_by_id: filters.created_by_id?.id || null,
    };
    dispatch(setLoading("tasks"));
    const res = await taskApi.newCustomers(filters_);
    dispatch(setTasks(res));
    dispatch(setLoading("tasks"));
  } catch (err) {
    dispatch(setLoading("tasks"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const maintenanceTasks = () => async (dispatch, getState) => {
  try {
    const filters = getState().task.task_maintenance.filters;
    const filters_ = {
      ...filters,
      user_id: filters.user_id?.id || null,
      city_id: filters.city_id?.id || null,
      responsible_id: filters.responsible_id?.id || null,
      cabinet_id: filters.cabinet_id?.id || null,
      client_id: filters.client_id?.id || null,
      team_leader_id: filters.team_leader_id?.id || null,
      status: filters.status ? filters.status.map((item) => item.key) : null,
      is_external:
        filters.is_external !== null ? (filters.is_external ? 1 : 0) : null,
      created_by_id: filters.created_by_id?.id || null,
    };
    dispatch(setLoading("task_maintenance"));
    const res = await taskApi.maintenanceTasks(filters_);
    dispatch(setMaintenanceTasks(res));
    dispatch(setLoading("task_maintenance"));
  } catch (err) {
    dispatch(setLoading("task_maintenance"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const subscriptionRequestTasks = () => async (dispatch, getState) => {
  try {
    const filters = getState().task.task_subscription_request.filters;

    const filters_ = {
      ...filters,
      user_id: filters.user_id?.id || null,
      city_id: filters.city_id?.id || null,
      responsible_id: filters.responsible_id?.id || null,
      cabinet_ids:
        filters.cabinet_ids.length > 0
          ? filters.cabinet_ids.map((item) => item.id)
          : [],
      status: filters.status ? filters.status.map((item) => item.key) : ["new"],
      created_by_id: filters.created_by_id?.id || null,
    };
    dispatch(setLoading("task_subscription_request"));
    const res = await taskApi.subscriptionRequestTasks(filters_);
    dispatch(setSubscriptionRequestTasks(res));
    dispatch(setLoading("task_subscription_request"));
  } catch (err) {
    dispatch(setLoading("task_subscription_request"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const status = () => async (dispatch, getState) => {
  try {
    dispatch(setLoading("status"));
    const res = await taskApi.status();
    dispatch(setStatus(res));
  } catch (err) {
    dispatch(setLoading("status"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const solutions = () => async (dispatch, getState) => {
  try {
    dispatch(setLoading("solutions"));
    const res = await taskApi.solutions();
    dispatch(setSolutions(res));
  } catch (err) {
    dispatch(setLoading("solutions"));
    dispatch(
      showNotification({
        type: "error",
        message: "حدث خطأ ما",
      })
    );
    throw new Error(err);
  }
};
const types = () => async (dispatch) => {
  try {
    dispatch(setLoading("types"));
    const res = await taskApi.types();
    dispatch(setTypes(res));
  } catch (err) {
    dispatch(setLoading("types"));
    dispatch(
      showNotification({
        type: "error",
        message: "حدث خطأ ما",
      })
    );
    throw new Error(err);
  }
};
const sendSolutions = (data) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("solutions"));
    await taskApi.addSolutions(data);
    dispatch(
      showNotification({
        type: "success",
        message: "تم إضافة الحلول بنجاح",
      })
    );
    dispatch(setLoading("solutions"));
    dispatch(getTaskByid(data.task_id));
    return true;
  } catch (err) {
    dispatch(setLoading("solutions"));
    dispatch(
      showNotification({
        type: "error",
        message: "حدث خطأ ما",
      })
    );
    throw new Error(err);
  }
};
const changeStatus = (data, page) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("tasks"));
    await taskApi.changeStatus({
      id: data.id,
      status: data.status,
    });
    dispatch(setLoading("tasks"));
    dispatch(
      showNotification({
        type: "success",
        message: "تم تغيير حالة المهمة بنجاح",
      })
    );
    if (data.from === "task_details") {
      dispatch(getTaskByid(data.id));
    } else {
      switch (page) {
        case "task":
          dispatch(index());
          break;
        case "maintenance":
          dispatch(maintenanceTasks());
          break;
        case "subscriptionRequest":
          dispatch(subscriptionRequestTasks());
          break;
        default:
          dispatch(index());
          break;
      }
    }
  } catch (err) {
    dispatch(setLoading("tasks"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const getTaskByid = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("task_details"));
    const res = await taskApi.showById(id);
    dispatch(setTaskDetails(res.data.data[0]));
    dispatch(setLoading("task_details"));
  } catch (err) {
    dispatch(setLoading("task_details"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const create = (data, formType) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("task"));
    const formData = new FormData();

    //if the form type is subscription request
    if (formType === "subscriptionRequest") {
      formData.append("type_id", 5); //"subscription_request"
      formData.append(
        "customer_id",
        data.customer_id ? data.customer_id.id : ""
      );
      formData.append("amount", toNumber(data.amount));
      formData.append("account_type_id", data.account_type_id?.id);
      formData.append("note", data.note);
      data.offer_id && formData.append("offer_id", data.offer_id);
    }
    //if the form type is maintenance and new customers
    else {
      if (data.type === 1) {
        formData.append("city_id", data.city_id?.id);
        formData.append("mobile", data.mobile);
        formData.append("name", data.name);
        formData.append(
          "cabinet_id",
          data.cabinet_id ? data.cabinet_id.id : ""
        );
        formData.append("pole_id", data.pole_id ? data.pole_id.id : "");
        if (data.client_id?.id)
          formData.append("client_id", data.client_id?.id);
      } else {
        formData.append(
          "customer_id",
          data.customer_id ? data.customer_id.id : ""
        );
        formData.append(
          "cabinet_id",
          data.cabinet_id ? data.cabinet_id.id : ""
        );
        formData.append("pole_id", data.pole_id ? data.pole_id.id : "");
        formData.append("is_external", data.is_external ? "1" : "0");
        formData.append("category", data.category);
      }
      formData.append("note", data.note);
      formData.append("status", data.status);
      formData.append("type_id", data.type);

      if (data.attachment.length > 0) {
        data.attachment.forEach((item, index) => {
          formData.append(`attachment[${index}]`, item);
        });
      }
    }

    await taskApi.create(formData);
    dispatch(setLoading("task"));
    dispatch(
      showNotification({
        type: "success",
        message: "تم إنشاء الطلب بنجاح",
      })
    );
    dispatch(
      setDialog({
        type: "",
      })
    );
    dispatch(resetForm());

    switch (formType) {
      case "subscriptionRequest":
        dispatch(subscriptionRequestTasks());
        break;
      case "maintenance":
        dispatch(maintenanceTasks());
        break;
      case "customer":
        dispatch(index());
        break;
      default:
        dispatch(index());
        break;
    }
  } catch (err) {
    dispatch(setLoading("task"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const deleteTask = (id) => async (dispatch, getState) => {
  try {
    await taskApi.delete(id);
    dispatch(
      showNotification({
        type: "success",
        message: "تم الحذف بنجاح",
      })
    );
    return {
      success: true,
    };
  } catch (err) {
    dispatch(
      showNotification({
        type: "error",
        message: "حدث خطأ ما",
      })
    );
    throw new Error(err);
  }
};
const deleteReply = (reply_id) => async (dispatch, getState) => {
  try {
    const { id } = getState().task.task_details.data;
    dispatch(setLoading("reply"));
    await taskApi.deleteReply(reply_id);
    dispatch(
      showNotification({
        type: "success",
        message: "تم حذف الرد بنجاح",
      })
    );
    dispatch(getTaskByid(id));
    dispatch(setLoading("reply"));
  } catch (err) {
    dispatch(setLoading("reply"));

    dispatch(
      showNotification({
        type: "error",
        message: "حدث خطأ ما",
      })
    );
    throw new Error(err);
  }
};
const reply = (data) => async (dispatch, getState) => {
  try {
    const { id } = getState().task.task_details.data;
    dispatch(setLoading("reply"));
    const formData = new FormData();
    formData.append("task_id", data.task_id);
    formData.append("note", data.note);
    formData.append("video", data.video);

    if (data.attachment.length > 0) {
      data.attachment.forEach((item, index) => {
        formData.append(`attachment[${index}]`, item);
      });
    }
    await api.post(`/api/v1/task-reply`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        const percent = Math.floor((loaded * 100) / total);
        dispatch(setReplyProgress(percent));
      },
    });
    // await taskApi.reply(formdata);
    dispatch(setLoading("reply"));
    dispatch(
      showNotification({
        type: "success",
        message: "تم إضافة الرد بنجاح",
      })
    );
    dispatch(setTaskReplyDialog());
    dispatch(getTaskByid(id));
  } catch (err) {
    dispatch(setReplyProgress(0));
    dispatch(setLoading("reply"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const assignResponsible = (data, page) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("responsible"));
    await taskApi.assignMultipleResponsible({
      tasks: data.tasks,
      responsible_id: data.responsible_id.id,
    });
    dispatch(setLoading("responsible"));
    dispatch(
      showNotification({
        type: "success",
        message: "تم تعيين المسؤول بنجاح",
      })
    );
    dispatch(setRsponsibleDialog());
    if (data.from === "task_details") {
      dispatch(getTaskByid(data.tasks[0]));
    } else {
      switch (page) {
        case "task":
          dispatch(index());
          break;
        case "maintenance":
          dispatch(maintenanceTasks());
          break;
        case "subscriptionRequest":
          dispatch(subscriptionRequestTasks());
          break;
        default:
          dispatch(index());
          break;
      }
    }
  } catch (err) {
    dispatch(setLoading("responsible"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const changeIsExternalStatus = (data) => async (dispatch, getState) => {
  try {
    await taskApi.changeIsExternalStatus(data);
    dispatch(
      showNotification({
        type: "success",
        message: "تم تغيير الحالة بنجاح",
      })
    );
    dispatch(getTaskByid(data.id));
    return {
      success: true,
    };
  } catch (err) {
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const getTasksNotifications = () => async (dispatch) => {
  try {
    const res = await taskApi.getTaskNotification();
    dispatch(setTaskNotifications(res));
  } catch (err) {
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const changeSubscriptionAccountRequestsTask = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("task"));
    await taskApi.changeSubscriptionRequests(data.id, {
      account_type_id: data.account_type_id.id,
      amount: toNumber(data.amount),
      offer_id: data.offer_id,
    });
    dispatch(setLoading("task"));
    dispatch(
      showNotification({
        type: "success",
        message: "تم تغيير نوع الاشتراك بنجاح",
      })
    );
    dispatch(setDialog());
    dispatch(resetForm());
    dispatch(subscriptionRequestTasks());
  } catch (err) {
    dispatch(setLoading("task"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const toExcel = () => async (dispatch, getState) => {
  try {
    const filters = getState().task.tasks.filters;
    dispatch(setLoading("tasks"));
    const res = await taskApi.exportToExcel({
      ...filters,
      city_id: filters.city_id?.id || null,
      responsible_id: filters.responsible_id?.id || null,
      team_leader_id: filters.team_leader_id?.id || null,
      status: filters.status ? filters.status.map((item) => item.key) : null,
      is_excel: 1,
      created_by_id: filters.created_by_id?.id || null,
    });
    let url = window.URL.createObjectURL(res.data);
    let a = document.createElement("a");
    a.href = url;
    a.download = "tasks.xlsx";
    a.click();
    dispatch(setLoading("tasks"));
  } catch (err) {
    dispatch(setLoading("tasks"));
    throw new Error(err);
  }
};
const toExcelTaskMaintenance = () => async (dispatch, getState) => {
  try {
    const filters = getState().task.task_maintenance.filters;
    dispatch(setLoading("task_maintenance"));

    const custom_filters = {
      ...filters,
      user_id: filters.user_id?.id || null,
      city_id: filters.city_id?.id || null,
      responsible_id: filters.responsible_id?.id || null,
      cabinet_id: filters.cabinet_id?.id || null,
      client_id: filters.client_id?.id || null,
      team_leader_id: filters.team_leader_id?.id || null,
      created_by_id: filters.created_by_id?.id || null,
      status: filters.status ? filters.status.map((item) => item.key) : null,
      is_external:
        filters.is_external !== null ? (filters.is_external ? 1 : 0) : null,
      is_excel: 1,
    };
    const res = await taskApi.exportToExcelMaintenance(custom_filters);
    let url = window.URL.createObjectURL(res.data);
    let a = document.createElement("a");
    a.href = url;
    a.download = "task_maintenance.xlsx";
    a.click();
    dispatch(setLoading("task_maintenance"));
  } catch (err) {
    dispatch(setLoading("task_maintenance"));
    throw new Error(err);
  }
};
const assignTeamLeader = (data, page) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("assign_team_leader"));
    await taskApi.assignTeamLeader({
      id: data.id,
      team_leader_id: data.team_leader_id?.id,
    });
    dispatch(setLoading("assign_team_leader"));
    dispatch(
      showNotification({
        type: "success",
        message: "تم تعيين المسؤول بنجاح",
      })
    );
    dispatch(setTeamLeaderDialog());
    if (data.from === "task_details") {
      dispatch(getTaskByid(data.tasks[0]));
    } else {
      switch (page) {
        case "task":
          dispatch(index());
          break;
        case "maintenance":
          dispatch(maintenanceTasks());
          break;
        default:
          dispatch(index());
          break;
      }
    }
  } catch (err) {
    dispatch(setLoading("assign_team_leader"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};

export const Tasks = {
  index,
  status,
  solutions,
  sendSolutions,
  getTaskByid,
  changeStatus,
  create,
  deleteTask,
  deleteReply,
  assignResponsible,
  reply,
  types,
  maintenanceTasks,
  changeIsExternalStatus,
  getTasksNotifications,
  subscriptionRequestTasks,
  changeSubscriptionAccountRequestsTask,
  toExcel,
  toExcelTaskMaintenance,
  assignTeamLeader,
  //   removeReply,
  //   changeReplyPrivacy,
};
