import { createSlice } from "@reduxjs/toolkit";
import { factory } from "../../api/apiFactory";
import { showNotification } from "./notificationSlice";
const clientApi = factory.get("clients");

const initialState = {
  clients: {
    data: [],
    loading: false,
    total: 0,
    filters: {
      skip: 0,
      take: 25,
      page: 0,
      search: "",
      is_active: null,
      city_id: null,
    },
    dialog: false,
  },
  client: {
    dialog: false,
    type: "add",
    loading: false,
    form: {
      id: "",
      name: "",
      user_name: "",
      password: "",
      confirm_password: "",
      mobile: "",
      email: "",
      city_id: null,
      tower_name: "",
      attachments: [],
      temp_attachments: [],
      cabinet_ids: [],
      subscription_fees: [],
      new_customer_fees: 0,
    },
    errors: {},
  },
  transactions: {
    data: [],
    loading: false,
    total: 0,
    filters: {
      skip: 0,
      take: 25,
      page: 0,
      search: "",
      start_date: null,
      end_date: null,
      type: null,
      client_id: null,
      customer_id: null,
      wallet_type: null,
      is_deposit: null,
      commissions_type: null,
    },
    dialog: false,
  },
  balance: {
    dialog: false,
    loading: false,
    form: {
      id: "",
      amount: "",
      note: "",
      amount_type: "+",
      is_collector: false,
    },
    errors: {},
  },
  commissions: {
    dialog: false,
    loading: false,
    form: {
      amount: "",
      note: "",
      customer_id: null,
      amount_type: "+",
    },
    errors: {},
  },
};

const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    setClients: (state, { payload }) => {
      state.clients.data = payload.data;
      state.clients.total = payload.total;
      state.clients.loading = false;
    },
    setTransactions: (state, { payload }) => {
      state.transactions.data = payload.data;
      state.transactions.total = payload.total;
      state.transactions.loading = false;
    },
    setLoading: (state, { payload }) => {
      state[payload].loading = !state[payload].loading;
    },
    setFilters: (state, { payload }) => {
      state.clients.filters = {
        ...state.clients.filters,
        ...payload,
      };
    },
    resetFilters: (state) => {
      state.clients.filters = initialState.clients.filters;
    },
    setTransactionsFilters: (state, { payload }) => {
      state.transactions.filters = {
        ...state.transactions.filters,
        ...payload,
      };
    },
    resetTransactionsFilters: (state) => {
      state.transactions.filters = initialState.transactions.filters;
    },
    setDialog: (state, { payload }) => {
      state.client.form.subscription_fees = [];
      const accountTypes = () => {
        const subscription_fees = payload.data?.subscription_fees;
        const account_types = payload?.account_types;
        const data =
          subscription_fees?.length > 0 ? subscription_fees : account_types;

        return data.map((item) => {
          return {
            subscription_type_id:
              subscription_fees?.length > 0
                ? item.subscription_type_id
                : item.id,
            amount: item.amount ? item.amount : "",
            name:
              subscription_fees?.length > 0
                ? item.subscription_type
                : item.name,
          };
        });
      };

      if (payload?.type === "edit") {
        state.client.form = {
          ...payload.data,
          city_id: {
            id: payload.data.city_id,
            name: payload.data.city,
          },
          cabinet_ids: payload.data.cabinet,
          attachments: [],
          temp_attachments: payload.data.attachments.map((item) => {
            return {
              id: item.id,
              path: item.path,
            };
          }),
          subscription_fees: accountTypes(),
        };
      } else if (payload?.type === "add") {
        state.client.form.subscription_fees = accountTypes();
      }

      state.client.dialog = !state.client.dialog;
      //clear after close
      if (!state.client.dialog) {
        state.client.form = initialState.client.form;
        state.client.type = "add";
      }
    },
    deletAttachment: (state, { payload }) => {
      state.client.form.temp_attachments =
        state.client.form.temp_attachments.filter(
          (item) => item.id !== payload
        );
    },
    setFiltersDialog: (state, { payload }) => {
      state.clients.dialog = !state.clients.dialog;
    },
    setTransactionsFiltersDialog: (state, { payload }) => {
      state.transactions.dialog = !state.transactions.dialog;
    },
    resetForm: (state) => {
      state.client.form = initialState.client.form;
    },
    setErrors: (state, { payload }) => {
      state.client.errors = payload;
    },
    setBalanceDialog(state, { payload }) {
      if (payload?.id) {
        state.balance.form = {
          ...state.balance.form,
          name: payload.name,
          id: payload.id,
        };
      }
      state.balance.form.is_collector = payload?.is_collector || false;
      state.balance.dialog = !state.balance.dialog;
      if (!state.balance.dialog) {
        state.balance.form = initialState.balance.form;
      }
    },
    setCommissionsDialog(state, { payload }) {
      if (payload?.id) {
        state.commissions.form = {
          id: payload.id,
          client_name: payload.client_name,
          customer_id: payload.customer_id
            ? {
                id: payload.customer_id,
                full_name: payload.customer_name,
                mobile: payload.customer_mobile,
              }
            : null,
          amount_type: "+",
        };
      }
      state.commissions.dialog = !state.commissions.dialog;
      if (!state.commissions.dialog) {
        state.commissions.form = initialState.commissions.form;
      }
    },
  },
});

export const {
  setClients,
  setLoading,
  setFilters,
  resetFilters,
  setDialog,
  resetForm,
  setErrors,
  setFiltersDialog,
  deletAttachment,
  setTransactions,
  setBalanceDialog,
  setTransactionsFilters,
  resetTransactionsFilters,
  setTransactionsFiltersDialog,
  setCommissionsDialog,
} = clientsSlice.actions;

export default clientsSlice.reducer;
const toNumber = (value) => {
  return typeof value === "number" ? value : parseInt(value.replace(/,/g, ""));
};
//axios
export const index = () => async (dispatch, getState) => {
  try {
    const filters = getState().clients.clients.filters;
    const filters_params = {
      ...filters,
      city_id: filters.city_id?.id || null,
    };
    dispatch(setLoading("clients"));
    const res = await clientApi.index(filters_params);
    dispatch(setClients(res.data));
  } catch (err) {
    dispatch(setLoading("clients"));
    throw new Error(err);
  }
};
export const transactions = () => async (dispatch, getState) => {
  try {
    const filters = getState().clients.transactions.filters;
    const filters_params = {
      ...filters,
      client_id: filters.client_id?.id || null,
      customer_id: filters.customer_id?.id || null,
    };
    dispatch(setLoading("transactions"));
    const res = await clientApi.getTransactions(filters_params);
    dispatch(setTransactions(res.data));
  } catch (err) {
    dispatch(setLoading("transactions"));
    throw new Error(err);
  }
};
export const getById = (id) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("clients"));
    const res = await clientApi.getById(id);
    // dispatch(setDialog(res.data));
    dispatch(setLoading("clients"));
  } catch (err) {
    dispatch(setLoading("clients"));
    throw new Error(err);
  }
};
export const addRemoveBalance = (data) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("balance"));

    const custom_data = {
      ...data,
      id: data.is_collector ? data.client_id?.id : data.id,
      amount:
        data.amount_type === "+"
          ? Math.abs(toNumber(data.amount))
          : -toNumber(data.amount),
    };
    data.is_collector
      ? await clientApi.addRemoveBalanceForCollector(custom_data)
      : await clientApi.addRemoveBalance(custom_data);
    dispatch(setLoading("balance"));
    dispatch(
      showNotification({
        message: "تم  إضافة العملية بنجاح",
        type: "success",
      })
    );
    dispatch(setBalanceDialog());

    //if permission type is is_collector true dont fetch index
    !data.is_collector && dispatch(index());
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    dispatch(setErrors(err.response.data.errors));
    dispatch(setLoading("balance"));
    throw new Error(err);
  }
};
export const create = (data) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("client"));
    let formData = new FormData();

    formData.append("name", data.name);
    formData.append("user_name", data.user_name);
    formData.append("password", data.password);
    formData.append("mobile", data.mobile);
    formData.append("email", data.email);
    formData.append("tower_name", data.tower_name);
    formData.append("city_id", data.city_id?.id);
    formData.append("note", data.note);
    formData.append("note", data.note);
    formData.append("new_customer_fees", toNumber(data.new_customer_fees));
    data.subscription_fees.forEach((item, index) => {
      formData.append(
        `subscription_fees[${index}]`,
        JSON.stringify({
          ...item,
          amount: toNumber(item.amount),
        })
      );
    });

    if (data.attachments.length > 0) {
      data.attachments.forEach((item, index) => {
        formData.append(`attachments[${index}]`, item);
      });
    }

    const res = await clientApi.create(formData);
    dispatch(setLoading("client"));
    dispatch(
      showNotification({
        message: "تم  إضافة الوكيل بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(resetForm());
    dispatch(index());
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    dispatch(setErrors(err.response.data.errors));
    dispatch(setLoading("client"));
    throw new Error(err);
  }
};
export const update = (data) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("client"));
    let formData = new FormData();
    formData.append("name", data.name);
    formData.append("user_name", data.user_name);
    formData.append("mobile", data.mobile);
    formData.append("email", data.email);
    formData.append("tower_name", data.tower_name);
    formData.append("city_id", data.city_id?.id);
    formData.append("note", data.note);
    formData.append("new_customer_fees", toNumber(data.new_customer_fees));
    data.subscription_fees.forEach((item, index) => {
      formData.append(
        `subscription_fees[${index}]`,
        JSON.stringify({
          ...item,
          amount: toNumber(item.amount),
        })
      );
    });

    const cabinets =
      data.cabinet_ids.length > 0
        ? data.cabinet_ids.map((item) => item.id)
        : [];
    formData.append("cabinet_ids", JSON.stringify(cabinets));
    formData.append("_method", "PUT");

    if (data.attachments.length > 0) {
      data.attachments.forEach((item, index) => {
        formData.append(`attachments[${index}]`, item);
      });
    }

    const res = await clientApi.update(data.id, formData);
    dispatch(setLoading("client"));
    dispatch(
      showNotification({
        message: "تم  تعديل الوكيل بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(resetForm());
    dispatch(index());
  } catch (err) {
    dispatch(
      showNotification({
        message: "حدث خطأ اثناء  تعديل الوكيل",
        type: "error",
      })
    );
    dispatch(setErrors(err.response.data.errors));
    dispatch(setLoading("client"));
    throw new Error(err);
  }
};
export const changePassword = (data) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("client"));
    const res = await clientApi.changePassword(data);
    dispatch(
      showNotification({
        message: "تم  تغيير كلمة مرور الوكيل بنجاح",
        type: "success",
      })
    );
    dispatch(setLoading("client"));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors,
        type: "error",
      })
    );
    dispatch(setErrors(err.response.data.errors));
    dispatch(setLoading("client"));
    throw new Error(err);
  }
};
export const changeStatus = (id) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("clients"));
    await clientApi.changeStatus(id);
    dispatch(
      showNotification({
        message: "تم  تغيير  حالة الوكيل بنجاح",
        type: "success",
      })
    );
    dispatch(setLoading("clients"));
    dispatch(index());
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors,
        type: "error",
      })
    );
    dispatch(setErrors(err.response.data.errors));
    dispatch(setLoading("clients"));
    throw new Error(err);
  }
};
export const deleteAttachment = (id) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("client"));
    await clientApi.deleteAttachment(id);
    dispatch(deletAttachment(id));
    dispatch(
      showNotification({
        message: "تم حذف الملف  بنجاح",
        type: "success",
      })
    );
    dispatch(setLoading("client"));
    dispatch(index());
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors,
        type: "error",
      })
    );
    dispatch(setErrors(err.response.data.errors));
    dispatch(setLoading("client"));
    throw new Error(err);
  }
};
export const deleteClient = (id) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("clients"));
    await clientApi.deleteClient(id);
    dispatch(
      showNotification({
        message: "تم حذف الوكيل بنجاح",
        type: "success",
      })
    );
    dispatch(setLoading("clients"));
    dispatch(index());
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors,
        type: "error",
      })
    );
    dispatch(setErrors(err.response.data.errors));
    dispatch(setLoading("clients"));
    throw new Error(err);
  }
};
export const assignClientCabent = (data) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("clients"));
    await clientApi.deleteClient(data);
    dispatch(
      showNotification({
        message: "تم حذف الوكيل بنجاح",
        type: "success",
      })
    );
    dispatch(setLoading("clients"));
    dispatch(index());
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors,
        type: "error",
      })
    );
    dispatch(setErrors(err.response.data.errors));
    dispatch(setLoading("clients"));
    throw new Error(err);
  }
};
export const transactionsToExcel = () => async (dispatch, getState) => {
  try {
    const filters = getState().clients.transactions.filters;
    dispatch(setLoading("transactions"));
    const res = await clientApi.exportToExcel({
      ...filters,
      start_date: filters.start_date || null,
      end_date: filters.end_date || null,
      type: filters.type || null,
      client_id: filters.client_id?.id || null,
      customer_id: filters.customer_id?.id || null,
      wallet_type: filters.wallet_type || null,
      is_excel: 1,
    });
    let url = window.URL.createObjectURL(res.data);
    let a = document.createElement("a");
    a.href = url;
    a.download = "transactions.xlsx";
    a.click();
    dispatch(setLoading("transactions"));
  } catch (err) {
    dispatch(setLoading("transactions"));
    throw new Error(err);
  }
};
export const addManualCommissions = (data) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("commissions"));
    await clientApi.addManualCommissions({
      id: data.id,
      customer_id: data.customer_id ? data.customer_id?.id : null,
      amount:
        data.amount_type === "+"
          ? Math.abs(toNumber(data.amount))
          : -toNumber(data.amount),
      note: data.note,
      type: data.type || null,
    });
    dispatch(setLoading("commissions"));
    dispatch(
      showNotification({
        message: "تم  تنفيذ العملية بنجاح",
        type: "success",
      })
    );
    dispatch(setCommissionsDialog());

    // if dialog was oppend from client page or transactions page fetch
    if (data.customer_id) {
      dispatch(transactions());
    } else {
      dispatch(index());
    }
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    dispatch(setErrors(err.response.data.errors));
    dispatch(setLoading("commissions"));
    throw new Error(err);
  }
};
export const exportClientsToExcel = () => async (dispatch, getState) => {
  try {
    const filters = getState().clients.clients.filters;
    dispatch(setLoading("clients"));
    const res = await clientApi.exportToExcelClients({
      ...filters,
      is_excel: 1,
    });
    let url = window.URL.createObjectURL(res.data);
    let a = document.createElement("a");
    a.href = url;
    a.download = "clients.xlsx";
    a.click();
    dispatch(setLoading("clients"));
  } catch (err) {
    dispatch(setLoading("clients"));
    throw new Error(err);
  }
};

export const Clients = {
  index,
  getById,
  create,
  update,
  changePassword,
  changeStatus,
  deleteAttachment,
  deleteClient,
  assignClientCabent,
  transactions,
  addRemoveBalance,
  transactionsToExcel,
  addManualCommissions,
  exportClientsToExcel,
};
